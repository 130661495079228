import {
    Button, IconButton, ThemeProvider, createTheme,
    Select, MenuItem, FormControl, InputLabel, Dialog, DialogActions,
    FormControlLabel, Checkbox
} from '@mui/material';
import { LightMode, DarkMode, Settings, ArrowCircleLeft, ArrowCircleRight } from '@mui/icons-material';

import './App.css';
import { useEffect, useState } from 'react';
import React from 'react'
import { stemmer } from 'stemmer'

const config = Object.assign({
    init: false,

    dark: false,
    fontSize: 12,
    splitSize: 15,
    dictionary: 'base',
    ttsRate: -0.3,

    wordsData: {},
    wordsSave: {},
    wordIndex: 0,
    wordReverse: false,

    wordMask: false,
}, JSON.parse(localStorage.getItem('config') || '{}'));

let currentAudio = new Audio();

currentAudio.addEventListener('ended', function () {
    fetchAndPlayTTS();
});

async function playAudio(word) {
    const url = `https://dict.youdao.com/dictvoice?audio=${word[0]}&type=2`;

    if (!currentAudio.paused) {
        currentAudio.pause();
        currentAudio.currentTime = 0; // 重置音频时间，以便下次可以重新开始播放
    }

    currentAudio.src = url;
    currentAudio.play();

    window.tts_blob = null;

    if (word[3]) {
        window.tts_blob = null;

        // 提前缓存所有段落的音频
        const response = await fetch('https://read-novel.rj-c.run/tts', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                text: word[3].replace('，', ','),
                rate: config.ttsRate,
                voiceName: 'en-US-AvaNeural',
            }),
        });

        // 检查响应是否成功
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // 获取二进制音频数据
        const arrayBuffer = await response.arrayBuffer();
        const blob = new Blob([arrayBuffer], { type: 'audio/mpeg' });

        // 创建一个从Blob对象生成的URL
        window.tts_blob = URL.createObjectURL(blob);
    }
}

async function fetchAndPlayTTS() {
    // 如果当前有音频正在播放，则停止
    if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0; // 重置音频到开始位置
    }

    if (window.tts_blob) {
        const tts_blob = window.tts_blob;
        window.tts_blob = null;
        currentAudio.src = tts_blob;
        setTimeout(() => currentAudio.play(), 500);
    }
}

async function get_dictionary(dict) {
    const res = await fetch('/dict/' + dict.file + '?v=11');
    const txt = await res.text();

    let currentRoot = '';
    let cache = {};

    for (const line of txt.split('\n')) {
        if (!line) continue;

        if (line.includes('|')) {
            currentRoot = line;
            cache[currentRoot] = [];
        } else {
            if (!cache[currentRoot]) cache[currentRoot] = [];
            cache[currentRoot].push(line.split(','));
        }
    }

    config.wordsData[dict.name] = [];

    for (const root of Object.keys(cache)) {
        config.wordsData[dict.name].push({
            root,
            data: cache[root]
        });
    }

    cache = null;
    localStorage.setItem('config', JSON.stringify(config));
}

const res = await fetch('/dict/dict.json?v=2');
const dictList = await res.json();

for (const dict of dictList) {
    await get_dictionary(dict);
}

if (!config.wordsData[config.dictionary]) {
    config.dictionary = 'root_words'
}

function highlightStemmedWord(word, text) {
    // 使用stemmer库获取单词的词干
    const stemmedWord = stemmer(word).toLowerCase();

    // 分割文本为单词数组，同时保留空格和标点符号
    const words = text.split(/(\b)/);

    // 遍历单词数组，将匹配的词干高亮
    const highlightedText = words.map((segment, index) => {
        // 检查每个词的词干是否与目标词干匹配
        if (stemmer(segment).toLowerCase() === stemmedWord) {
            // 如果匹配，则用<i>标签包裹
            return <i key={index}>{segment}</i>;
        }
        // 如果不匹配，正常返回段落文本
        return segment;
    });

    // 返回包含高亮文本的React元素
    return <>{highlightedText}</>;
}

function test(name) {
    const table = `
    3月23日	基础高频1	
    3月24日	假如给我三天光明	基础高频1
    3月25日	假如给我三天光明	基础高频1
    3月26日	假如给我三天光明	基础高频1
    3月27日	小王子	
    3月28日	小王子	假如给我三天光明
    3月29日	小王子	基础高频1
    3月30日	基础高频2	
    3月31日	纳尼亚传奇	基础高频2
    4月1日	纳尼亚传奇	假如给我三天光明
    4月2日	纳尼亚传奇	基础高频2
    4月3日	纳尼亚传奇	小王子
    4月4日	夏洛的网	
    4月5日	夏洛的网	基础高频1
    4月6日	夏洛的网	假如给我三天光明
    4月7日	夏洛的网	小王子
    4月8日	变形记	
    4月9日	变形记	基础高频2
    4月10日	变形记	小王子
    4月11日	变形记	纳尼亚传奇
    4月12日	动物农场	
    4月13日	动物农场	基础高频2
    4月14日	动物农场	夏洛的网
    4月15日	动物农场	变形记
    4月16日	霍比特人	
    4月17日	霍比特人	基础高频2
    4月18日	霍比特人	纳尼亚传奇
    4月19日	霍比特人	夏洛的网
    
    
`.trim();

    const now = new Date();

    for (const line of table.split('\n')) {
        const arr = line.split(/\t+/);

        if ((now.getMonth() + 1) + '月' + now.getDate() + '日' === arr[0].trim() && (arr[1].includes(name) || arr[2].includes(name))) {
            return true;
        }
    }

    return false;
}

function App() {
    const [, updateApp] = useState(null);
    const [init, setInit] = useState(true);
    const [showSetting, setShowSetting] = useState(!config.init);

    const setConfig = (name, value) => {
        if (name) {
            config[name] = value;
        }

        localStorage.setItem('config', JSON.stringify(config));
        updateApp(Date.now());
    }

    const darkMode = () => {
        setConfig('dark', !config.dark);
    }

    const darkTheme = createTheme({
        palette: {
            mode: config.dark ? 'dark' : 'light',
        },
    });

    const metaThemeColorElem = document.querySelector("meta[name=theme-color]");

    if (metaThemeColorElem) {
        metaThemeColorElem.setAttribute("content", config.dark ? '#121212' : '#FAFAFA');
    }

    function playWordAudio() {
        const wordsData = config.wordsData[config.dictionary];
        const currentRoot = wordsData[0] || config.wordsData[0] || { root: '', data: [] };
        let splitData = currentRoot.data;

        if (config.wordReverse) {
            splitData = splitData.concat().reverse();
        }

        const word = splitData[config.wordIndex];
        if (word) playAudio(word);
    }

    const wordsData = config.wordsData[config.dictionary] || [];
    const currentRoot = wordsData[0] || { root: '', data: [] };
    let data = (currentRoot || { data: [] }).data;

    if (config.wordReverse) {
        data = data.concat().reverse();
    }

    const word = data[config.wordIndex] || ['', '', ''];

    // 统计
    let allNumber = 0;

    for (const words of wordsData || []) {
        allNumber += words.data.length;
    }


    // 翻页和点击
    const nextAction = async () => {
        if (init) {
            setInit(false);
        }

        await setConfig();
        playWordAudio();
    }

    window.nextAction = nextAction;

    const prevPage = async () => {
        config.wordIndex--;

        if (config.wordIndex < 0) {
            config.wordIndex = 0;
        }

        await setConfig();
        playWordAudio();
    }

    const nextPage = async () => {
        config.wordIndex++;

        await setConfig();
        playWordAudio();
    }

    useEffect(() => {
        const handleKeydown = e => {
            if ([34, 38].includes(e.keyCode)) {
                prevPage();
            }
            else if ([33, 13, 40].includes(e.keyCode)) {
                nextPage();
            }
            else if ([39, 37].includes(e.keyCode)) {
                playWordAudio();
            }
        };

        document.addEventListener('keydown', handleKeydown);

        return () => {
            document.removeEventListener('keydown', handleKeydown);
        }
    });

    return (
        <ThemeProvider theme={darkTheme}>
            <div className={"app " + (config.dark ? 'dark' : '')}>
                <header>
                    <IconButton size="small" onClick={() => setShowSetting(true)} className="darkMode">
                        <Settings size="small" />
                    </IconButton>

                    <span>{config.wordIndex}/{allNumber}</span>

                    <Dialog open={showSetting} >
                        <div className="setting_box">

                            <FormControl>
                                <InputLabel id="dictionary">选择词典</InputLabel>

                                <Select size="small"
                                    label="dictionary"
                                    value={config.dictionary}
                                    onChange={(e) => {
                                        if (!config.wordsSave[e.target.value]) {
                                            config.wordsSave[e.target.value] = {}
                                        }

                                        if (!config.wordsSave[config.dictionary]) {
                                            config.wordsSave[config.dictionary] = {
                                                wordIndex: 0,
                                            }
                                        }

                                        config.wordsSave[e.target.value].wordIndex = config.wordIndex;
                                        config.wordIndex = config.wordsSave[config.dictionary].wordIndex;

                                        setConfig('dictionary', e.target.value);
                                    }}>

                                    {dictList.map((dict, i) => (
                                        <MenuItem value={dict.name}>{test(dict.name) ? '* ' : ''}{dict.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl>
                                <InputLabel id="fontSizeLabel">字体大小</InputLabel>

                                <Select size="small"
                                    label="fontSizeLabel"
                                    value={config.fontSize}
                                    onChange={(e) => setConfig('fontSize', e.target.value)}>
                                    <MenuItem value={12}>12</MenuItem>
                                    <MenuItem value={13}>13</MenuItem>
                                    <MenuItem value={14}>14</MenuItem>
                                    <MenuItem value={15}>15</MenuItem>
                                    <MenuItem value={16}>16</MenuItem>
                                    <MenuItem value={17}>17</MenuItem>
                                    <MenuItem value={18}>18</MenuItem>
                                    <MenuItem value={19}>19</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl>
                                <InputLabel id="ttsRate">语速</InputLabel>
                                <Select
                                    size="small"
                                    value={config.ttsRate}
                                    label="ttsRate"
                                    onChange={(e) => setConfig('ttsRate', e.target.value)}>
                                    <MenuItem value={-0.5}>-0.5</MenuItem>
                                    <MenuItem value={-0.4}>-0.4</MenuItem>
                                    <MenuItem value={-0.3}>-0.3</MenuItem>
                                    <MenuItem value={-0.2}>-0.2</MenuItem>
                                    <MenuItem value={-0.1}>-0.1</MenuItem>
                                    <MenuItem value={0}>0 (默认)</MenuItem>
                                    <MenuItem value={0.1}>0.1</MenuItem>
                                    <MenuItem value={0.2}>0.2</MenuItem>
                                    <MenuItem value={0.3}>0.3</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControlLabel
                                control={<Checkbox size="small" checked={config.wordReverse} 
                                onChange={e=>{ config.wordReverse = e.target.checked; updateApp(); }} />}
                                label="翻转词典" />
                        </div>
                        <DialogActions>
                            <Button onClick={async () => {
                                config.wordsSave = {};
                                config.wordIndex = 0;
                                await setConfig();
                            }}>重置</Button>
                            <Button onClick={() => { setShowSetting(false); setConfig('init', true) }}>设置</Button>
                        </DialogActions>
                    </Dialog>

                    <IconButton size="small" onClick={darkMode} className="darkMode">
                        {!config.dark ? <DarkMode size="small" /> : <LightMode size="small" />}
                    </IconButton>
                </header>

                <div className="box word_box" style={{ fontSize: config.fontSize + 'px' }}>
                    <div className='word_base'>
                        <span className='word0'>{word[0]}</span>
                        <span className='word1'>[{word[1]}]</span>
                    </div>

                    <div className='word_base'>
                        {(word[2] || '').split(/  /g).map(line => (
                            <p className='word2'>{line}</p>
                        ))}
                    </div>

                    {word[3] ? <div className='word_base dict_box'>
                        <span className="cn">{word[4]}</span>
                        <span className="en">{highlightStemmedWord(word[0], word[3].replace(/， {0,}/g, ', '))}</span>
                    </div> : ''}

                    <div className="action_box">
                        <div className="_prev_box" onClick={prevPage}></div>
                        <div className="_action_box" onClick={nextAction}></div>
                        <div className="_next_box" onClick={nextPage}></div>
                    </div>
                </div>

                <footer>
                    <div className="page_button">
                        <IconButton
                            onClick={prevPage}
                            size="small">
                            <ArrowCircleLeft />
                        </IconButton>

                        <IconButton
                            onClick={nextPage}
                            size="small">
                            <ArrowCircleRight />
                        </IconButton>
                    </div>
                </footer>

            </div>
        </ThemeProvider>
    );
}

export default App;
